<template>
  <layout-view-wrapper-narrow class="content">
    <BaseH1 :text="'Zaloguj się'" :short="true" />

    <div class="wrapper-inputs">
      <InputTextEmail v-model="loginEmail" />
      <InputTextPassword v-model="password" />
      <ButtonLink :to="ROUTE_FORGOT_PASSWORD" :label="'Nie pamientam hasła'" />
    </div>

    <ButtonPrimaryNext
      @click="handleLoginLocal(loginEmail, password)"
      :isDisabled="isButtonDisabled"
    />
  </layout-view-wrapper-narrow>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import useAuth from "@/composables/useAuth";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextPassword from "@/components/UI/InputTextPassword.vue";
import InputTextEmail from "@/components/UI/InputTextEmail.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import { ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME } from "@/router/constants";

export default {
  components: {
    InputTextPassword,
    InputTextEmail,
    ButtonPrimaryNext,
    BaseH1,
    ButtonLink,
  },

  setup() {
    const loginEmail = ref("");
    const password = ref("");

    const isButtonDisabled = computed(() => {
      return !loginEmail.value || !password.value;
    });

    const { handleLoginLocal } = useAuth();

    const ROUTE_FORGOT_PASSWORD = {
      name: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
    };

    return {
      handleLoginLocal,
      loginEmail,
      password,
      isButtonDisabled,
      ROUTE_FORGOT_PASSWORD,
    };
  },
};
</script>

<style scoped>
.content {
  justify-content: flex-start;
  row-gap: 60px;
}
.wrapper-inputs {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}

@media (max-width: 1200px) {
  .content {
    row-gap: 80px;
  }
}
</style>
